import { FC, useState } from 'react';
// Components
import { Popover } from 'Components';
// Styles
import styles from './tooltipItem.module.scss';
// Helpers
import { LogHelperSingleton } from 'Helpers';

type TChatItemProps = {
    item: string,
    tooltipText: string,
    link?: string,
    selectedTab: string,
    searchText: string,
    question: string
}

export const TooltipItem: FC<TChatItemProps> = ({ item, tooltipText, link, selectedTab, searchText, question }) => {
    const [tooltipSource, setTooltipSource] = useState<HTMLSpanElement | null>(null);
    const [isTooltipShown, setIsTooltipShown] = useState<boolean>(false);

    const handleReferenceClick = () => {
        // log
        LogHelperSingleton.logWithProperties("ResultClick", {
            "SearchTab": `${selectedTab}`,
            "SearchSubTab": "question-answering",
            "SearchText": `${searchText}`,
            "Question": `${question}`,
            "Link": `${link ?? "No link"}`
        });
    }

    return (
        <>
            <span
                onMouseEnter={() => setIsTooltipShown(true)}
                onMouseLeave={() => setIsTooltipShown(false)}
                ref={setTooltipSource}
                className={styles.tooltipSource}
                onClick={handleReferenceClick}
            >
                {link ? <a href={link} target="_blank" rel="noopener noreferrer">{item}</a> : item}
            </span>
            {isTooltipShown && tooltipText &&
                <Popover
                    onMouseEnter={() => setIsTooltipShown(true)}
                    onMouseLeave={() => setIsTooltipShown(false)}
                    referenceEl={tooltipSource}
                    isOpen={true}
                    extraClassName={styles.tooltip}
                    showInPortal={true}
                >
                    {tooltipText}
                </Popover>
            }
        </>
    );
};
