import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
// Styles
import styles from "./tableDataCell.module.scss";

type TTableDataCellProps = {
    text: string,
}

export const TableDataCell: FC<TTableDataCellProps> = ({ text }) => {
    // State
    const [doesNeedReadMore, setDoesNeedReadMore] = useState<boolean>(false);
    const [isReadMoreExpanded, setIsReadMoreExpanded] = useState<boolean>(false);

    // Ref
    const divContainerRef = useRef<HTMLDivElement>(null);

    const determineIfReadMoreIsNeeded = useCallback(() => {
        // safety-checks
        if (!divContainerRef.current) { return; }

        const needsReadMore = divContainerRef.current.clientHeight !== divContainerRef.current.scrollHeight;
        setDoesNeedReadMore(needsReadMore);
    }, [divContainerRef]);

    const toggleReadMore = (): void => {
        setIsReadMoreExpanded(!isReadMoreExpanded);
    };

    useEffect(() => {
        determineIfReadMoreIsNeeded();
    }, [determineIfReadMoreIsNeeded]);

    return (
        <td>
            <div className={`${styles.text} ${isReadMoreExpanded ? styles.unclampText : ""}`} ref={divContainerRef}>
                {text}
            </div>
            {(doesNeedReadMore || isReadMoreExpanded) ?
                <p onClick={toggleReadMore} className={styles.readMore}>
                    {isReadMoreExpanded ?
                        <>
                            Collapse text
                            <FontAwesomeIcon icon={faChevronUp} />
                        </> :
                        <>
                            Read more
                            <FontAwesomeIcon icon={faChevronDown} />
                        </>
                    }
                </p>
                :
                null
            }
        </td>
    );
}
