// node_modules
import { FC } from "react";
// Styles
import styles from "./checkbox.module.scss";

type TCheckboxProps = {
    isChecked?: boolean,
    isCheckboxShown?: boolean,
    isPartiallySelected?: boolean,
    onCheckboxChange: (isChecked: boolean) => void,
    id?: string,
    isDisabled?: boolean,
    title?: string,
    text?: string,
    theme?: string
};

export const Checkbox: FC<TCheckboxProps> = ({
    id,
    isChecked,
    isPartiallySelected = false,
    isCheckboxShown = true,
    onCheckboxChange,
    isDisabled,
    title,
    text,
    theme = "black"
}: TCheckboxProps) => (
    <label
        className={[
            theme ? styles[theme] : "",
            isChecked ? styles.checked : "",
            isPartiallySelected ? styles.partiallySelected : "",
            styles.checkboxContainer,
            isCheckboxShown ? "" : styles.hide,
            isDisabled ? styles.disabled : ""
        ].join(" ")}
    >
        <input
            disabled={isDisabled}
            onChange={() => { if (!isDisabled) { onCheckboxChange(!isChecked); } }}
            id={id}
            type="checkbox"
            checked={isChecked}
        />
        <div
            title={title}
            className={[styles.checkmark, isDisabled ? styles.disabled : ""].join(" ")}
        />
        {text && <span className={[styles.text, isDisabled ? styles.disabled : ""].join(" ")}>{text}</span>}
    </label>
);
