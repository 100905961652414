import { FC, useEffect, useRef, useState } from "react";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// Components
import { Popover, Checkbox, Button } from "Components";
// Hooks
import { useClickOutsideRef } from "Hooks";
// Styles
import styles from "./filters.module.scss";

type TFilterSelectionButton = {
  years: { startingYear: number, currentYear: number};
  reset: boolean;
  hasProperties: boolean;
  onFiltersChange: ( hasCitationScore: boolean, dateRange: { start: number; end: number }) => void;
};

export const Filters: FC<TFilterSelectionButton> = ({
  years,
  reset,
  hasProperties,
  onFiltersChange
}) => {
  // State
  const [filterSelectionButtonRef, setFilterSelectionButtonRef] =
    useState<HTMLDivElement | null>(null);
  const [isPopoverShown, setIsPopoverShown] = useState<boolean>(false);
  const [hasCitationScore, setHasCitationScore] = useState<boolean>(true);
  const [dateRange, setDateRange] = useState<{ start: number; end: number }>({
    start: years.startingYear,
    end: years.currentYear
  });
  const [filterDifferences, setFilterDifferences] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  // Ref
  const filterSelectionButtonContainerRef = useRef<HTMLDivElement>(null);

  // Custom hooks
  useClickOutsideRef(filterSelectionButtonContainerRef, () => {
    setIsPopoverShown(false);
  });

  // Logic
  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const value = e.target.value ? parseInt(e.target.value) : (type === "start" ? dateRange.start : dateRange.end);
    setDateRange((prev) => ({
      start: type === "start" ? value : prev.start,
      end: type === "end" ? value : prev.end,
    }));
    setIsDisabled(false);
  };

  const onCheckboxChange = (isChecked: boolean) => {
    setHasCitationScore(isChecked);
    setIsDisabled(false);
  };

  const onOutOfLimits = () => {
    const { start, end } = dateRange;
    const { startingYear, currentYear } = years;

    if (start > end) {
      if (!(start < startingYear || end > currentYear)) {
        return "The start year can't be more recent than the end year.";
      }
      
      if (start < startingYear || end > currentYear) {
        return `The start year can't be more recent than the end year. And the range should be between ${years.startingYear} and ${years.currentYear}.`;
      }
    }

    if (start < startingYear || end > currentYear) {
      return `The publication year should be between ${years.startingYear} and ${years.currentYear}.`;
    }

    return;
  }

  const onCancelFilters = () => {
    setIsPopoverShown(false);
  };

  const filtersApplied = () => {
    const differences = [
      hasCitationScore !== true,
      dateRange.start !== years.startingYear || dateRange.end !== years.currentYear,
    ].filter(Boolean).length;

    return differences > 0 ? differences : 0;
  };

  const onApplyFilters = () => {
    setFilterDifferences(filtersApplied());
    setIsDisabled(true);
    setIsPopoverShown(false);
    onFiltersChange(hasCitationScore, dateRange);
  };

  useEffect(() => {
    setHasCitationScore(true);
    setDateRange({ start: 1850, end: new Date().getFullYear() });
    setFilterDifferences(0);
    setIsDisabled(true);
  }, [reset]);

  return (
    <div className={styles.filters} ref={filterSelectionButtonContainerRef}>
      <div ref={setFilterSelectionButtonRef}>
        <Button
          titleAttribute="Filters"
          onClick={() => setIsPopoverShown(true)}
          title=""
          buttonType="secondary"
          extraClassName={styles.filtersButton}
          iconName={faFilter}
        >
          <span>Filter</span>
          {filterDifferences > 0 && (
            <span className={styles.filtersApplied}>{filterDifferences}</span>
          )}
        </Button>
      </div>
      <Popover
        referenceEl={filterSelectionButtonRef}
        isOpen={isPopoverShown}
        placement="bottom-start"
        extraClassName={styles.popover}
      >
        <div className={styles.container}>
          <p>Filters</p>
          <div>
            <h4>Publication year</h4>
            <div className={styles.inputs}>
              <div>
                <p>Start</p>
                <div className={`${styles.inputContainer} ${onOutOfLimits() ? styles.inputContainerBorder : ''}`}>
                  <input
                    type="number"
                    max={years.currentYear}
                    value={dateRange.start}
                    onChange={(e) => {
                      onInputChange(e, "start");
                    }}
                  />
                  {onOutOfLimits() && <FontAwesomeIcon icon={faTriangleExclamation} />}
                </div>
              </div>
              <p className={styles.seperator}>-</p>
              <div>
                <p>End</p>
                <div className={`${styles.inputContainer} ${dateRange.end > years.currentYear ? styles.inputContainerBorder : ''}`}>
                  <input
                  type="number"
                  max={years.currentYear}
                  value={dateRange.end}
                  onChange={(e) => {
                    onInputChange(e, "end");
                  }}
                  />
                  {dateRange.end > years.currentYear && <FontAwesomeIcon icon={faTriangleExclamation} />}
                </div>
              </div>
            </div>
            {onOutOfLimits() && <p className={styles.message}>{onOutOfLimits()}</p>}
          </div>
          {hasProperties && (
            <div>
              <h4>Properties</h4>
              <Checkbox
                text={"Has citation score"}
                theme="textLabelFilters"
                isChecked={hasCitationScore}
                onCheckboxChange={(isChecked: boolean) => {
                  onCheckboxChange(isChecked);
                }}
              />
            </div>
          )}
          <div className={styles.buttons}>
            <Button
              title="Apply"
              buttonType="primary"
              isDisabled={isDisabled || onOutOfLimits() ? true : false}
              onClick={onApplyFilters}
            />
            <Button
              title="Cancel"
              buttonType="secondary"
              onClick={onCancelFilters}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
};