import { FC, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faCaretDown } from '@fortawesome/free-solid-svg-icons';
// Components
import { Button, Popover } from 'Components';
// Hooks
import { useClickOutsideRef } from 'Hooks';
// Styles
import styles from "./dropdown.module.scss";

type TDropdownProps = {
    options: any[],
    extraClassNames?: { container?: string, dropdownButton?: string},
    onOptionSelect?: (option: string) => void,
    iconName?: IconDefinition,
    selectedOption?: any,
    buttonTitleAttribute?: string
}

export const Dropdown: FC<TDropdownProps> = ({
    options, extraClassNames = {}, onOptionSelect, iconName, selectedOption, buttonTitleAttribute
}) => {
    // State
    const [buttonRef, setButtonRef] = useState<HTMLDivElement | null>(null);
    const [isPopoverShown, setIsPopoverShown] = useState<boolean>(false);

    // Ref
    const buttonContainerRef = useRef<HTMLDivElement>(null);

    // Logic
    const showPopover = () => {
        setIsPopoverShown(true);
    }

    const onOptionClick = (option: string) => {
        if (onOptionSelect && option !== selectedOption) {
            onOptionSelect(option);
            setIsPopoverShown(false);
        }
    }

    // Custom hooks
    useClickOutsideRef(buttonContainerRef, () => { setIsPopoverShown(false); });
    
    return (
        <div className={extraClassNames?.container ?? ""} ref={buttonContainerRef}>
            <div ref={setButtonRef}>
                <Button titleAttribute={buttonTitleAttribute} extraClassName={extraClassNames?.dropdownButton ?? ""} onClick={showPopover} title={selectedOption ?? ""} buttonType="secondary" iconName={iconName}>
                    <FontAwesomeIcon icon={faCaretDown} />
                </Button>
            </div>
            <Popover referenceEl={buttonRef} isOpen={isPopoverShown} placement="bottom-end">
                <div>
                    <ul>
                        {options.map((option, index) => {
                            return (
                                <li className={`${styles.option} ${selectedOption === option ? styles.selected : ""}`} key={index} onClick={() => onOptionClick(option)}>{option}</li>
                            );
                        })}
                    </ul>
                </div>
            </Popover>
        </div>
    );
}
