import { FC } from "react";
import { IconDefinition as regularIconDefinition } from "@fortawesome/free-regular-svg-icons";
import { IconDefinition as solidIconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./button.module.scss";

type TButtonProps = {
    id?: string,
    title: string,
    extraClassName?: string,
    isColorless?: boolean,
    isSmallSize?: boolean,
    iconName?: regularIconDefinition | solidIconDefinition,
    onClick?: () => void,
    isDisabled?: boolean,
    buttonType?: "primary" | "secondary" | "cancel" | "tertiary" ,
    styleProps?: ["iconOnRight" | "noWrap"]
    children?: React.ReactNode,
    titleAttribute?: string
}

export const Button: FC<TButtonProps> = ({
    id, title, extraClassName, isColorless, isSmallSize, iconName, onClick, isDisabled, buttonType, children, titleAttribute, styleProps
}: TButtonProps) => {
    
    const getClassName = (): string => {
        const classNameList = [styles.findestButton];
        if (extraClassName) classNameList.push(extraClassName);
        if (isColorless) classNameList.push(styles.colorless);
        if (isSmallSize) classNameList.push(styles.small);
        if (isDisabled) classNameList.push(styles.disabled);
        if (buttonType) classNameList.push(styles[buttonType]);
        if (styleProps)  {
            styleProps.forEach(prop => {
                classNameList.push(styles[prop]);
            });
        }

        return classNameList.join(" ");
    };
    
    return (
        <button disabled={isDisabled} type="button" id={id} onClick={onClick} className={getClassName()} title={titleAttribute ? titleAttribute : ""}>
            {title}
            {iconName ? <FontAwesomeIcon icon={iconName} /> : null }
            {children}
        </button>
    );
};