// Helpers
import { PendoHelperSingleton } from "./Trackers";
// Types
import { TLogEventName } from "Types";

type TEventPropertyName = "SearchTab" | "SearchSubTab" | "SearchText" | "Question" | "Link";
type TEventProperties = Partial<Record<TEventPropertyName, string | number>>;

export class LogHelper {
    public init(userEmailAddress: string, userCompanyName: string): void {
        // safety-checks
        if (!userEmailAddress || !userCompanyName) {
            return;
        }

        // init Pendo
        PendoHelperSingleton.initializePendo(userEmailAddress, userCompanyName);
    }

    public log(eventName: TLogEventName): void {
        // safety-checks
        if (!eventName) {
            return;
        }

        // log event in Pendo
        PendoHelperSingleton.trackEvent(eventName);
    }

    public logWithProperties(eventName: TLogEventName, eventProperties: TEventProperties): void {
        // safety-checks
        if (!eventName || !eventProperties) {
            return;
        }

        // log event in Pendo
        if (Object.keys(eventProperties).length > 0) {
            PendoHelperSingleton.trackEventWithProperties(eventName, eventProperties);
        } else {
            this.log(eventName);
        }
    }
}

export const LogHelperSingleton = new LogHelper();