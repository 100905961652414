/* eslint-disable no-unreachable */
// node_modules
import axios from "axios";
// Helpers
import { AxiosHelperSingleton } from "Helpers";
// Types
import { TPatentData } from "Types";
//  Patent Filters: import TFilters from Types
export class UsePatentController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}/api/use-patent`;

    public async sendPatentChatRequest(chatMessage: string): Promise<string> { //  Patent Filters: add as a second parameter: filters: TFilters
        try {
            // get chat response
            const response = await axios
                .post<{data: string, response: string}>(`${this._resourcePath}/chat`, {
                    message: chatMessage,
                    // dateRange: filters.dateRange /* Patent Filters: Uncomment */
                });

            // deal with response
            if (response && response.data.response) {
                return response.data.response;
            } else {
                // otherwise return ""
                return "";
            }
        } catch (error) {
            console.error(error);
            // return ""
            return "";
        }
    }

    public async sendPatentSearchRequest(searchText: string): Promise<TPatentData[]> { // add filters: TFilters, to get filtered patents
        try {
            // get search response
            const response = await axios
                .post<{ patents: TPatentData[]}>(`${this._resourcePath}/search`, {
                    query: searchText,
                    // dateRange: filters.dateRange  /* Patent Filters: Uncomment */
                });

            // deal with response
            if (response && response.data) {
                return response.data.patents.map((patentData: TPatentData) => {
                    return {
                        ...patentData,
                        score: Math.round(Number(patentData.score) * 100).toString()
                    };
                });
            } else {
                // otherwise return []
                return [];
            }
        } catch (error) {
            console.error(error);
            // return []
            return [];
        }
    }
}

export const UsePatentControllerSingleton = new UsePatentController();
