import { FC, useState, useMemo, FormEvent, useEffect, useCallback } from 'react';
import { faSearch, faFile, faFlask, faAward } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlaneTop, faComment } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate, useLocation } from "react-router-dom";
// Components
import { Button, Textbox, Tabs, Chat, Search, Filters } from 'Components';
// Constants
import { ErrorConstants } from 'Constants';
// Controllers
import { UsePatentControllerSingleton, UseScienceControllerSingleton } from 'Controllers';
// Enums
import { ToastTypeEnum } from 'Enums';
// Helpers
import { LogHelperSingleton, ToastHelperSingleton, LocalStorageHelperSingleton } from 'Helpers';
// Types
import { TPatentData, TFilters } from 'Types';
// Styles
import styles from "./app.module.scss";
// Images
import IgorAILogo from "./Assests/Images/IGORai_logo_black.png";

type TQuestionAnswerPair = {
    question: string,
    answer: string,
};

export const App: FC = () => {
    // Hooks
    const navigate = useNavigate();
    const location = useLocation();
    
    // State
    const [searchText, setSearchText] = useState<string>((new URLSearchParams(location.search).get("q") ?? ""));
    const years = { startingYear: 1850, currentYear: new Date().getFullYear()}
    const [filters, setFilters] = useState<TFilters>({ hasCitationScore: true, dateRange: { start: `${years.startingYear}-01-01`, end: `${years.currentYear}-12-31` } });
    const [reset, setReset] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [questionAnswerPairs, setQuestionAnswerPairs] = useState<TQuestionAnswerPair[]>([]);
    const [patentDataResults, setPatentDataResults] = useState<TPatentData[]>([]);

    // Memo
    const selectedTabFromPath = useMemo(() => !!(location.pathname.split("/")[1]) ? location.pathname.split("/")[1] : "science", [location.pathname]);
    const selectedSubTabFromPath = useMemo(() => !!(location.pathname.split("/")[2]) ? location.pathname.split("/")[2] : "question-answering", [location.pathname]);
    const isSelectedTabFromPathValid = useMemo(() => ["science", "patents"].indexOf(selectedTabFromPath) > -1, [selectedTabFromPath]);
    const isSelectedSubTabFromPathValid = useMemo(() => ["question-answering", "documents"].indexOf(selectedSubTabFromPath) > -1, [selectedSubTabFromPath]);
    const bannerTextQueryParameter = useMemo(() => {
        const params = new URLSearchParams(location.search);
        return params.get("bannerText");
    }, [location.search]);
    const searchButtonTitle = useMemo(() => {
        const params = new URLSearchParams(location.search);
        const actionType = params.get("buttonName");

        if (actionType === "search") {
            return "Search";
        }
        return "Send";
    }, [location.search]);

    const initPendo = useCallback(() => {
        // get user email address and tenant name from query params
        const queryParams = new URLSearchParams(location.search);
        const queryParamUserEmail = queryParams.get("email");
        const queryParamUserEmailTenantName = queryParams.get("tenant");

        if (queryParamUserEmail && queryParamUserEmailTenantName) {
            LogHelperSingleton.init(queryParamUserEmail, queryParamUserEmailTenantName);
            // set user email address and tenant name in local storage
            LocalStorageHelperSingleton.setItem({ email: queryParamUserEmail, tenant: queryParamUserEmailTenantName }, "userProperties");
        } else {
            // get user email address and tenant name from local storage
            const userProperties = LocalStorageHelperSingleton.getItem<{ email: string, tenant: string}>("userProperties");
            if (userProperties) {
                LogHelperSingleton.init(userProperties.email, userProperties.tenant);
            } else {
                // if user email address and tenant name are not available in local storage
                LogHelperSingleton.init("Unknown", "Unknown");
            }
        }
    }, [location.search]);

    useEffect(() => {
        // if tab or sub-tab is not valid, redirect to the default tab and sub-tab
        if (!isSelectedTabFromPathValid || !isSelectedSubTabFromPathValid) {
            const queryParams = new URLSearchParams(location.search);
            navigate(`/science/question-answering?${queryParams.toString()}`);
        }
    }, [isSelectedTabFromPathValid, isSelectedSubTabFromPathValid, navigate, location.search]);

    useEffect(() => {
        initPendo();
    }, [initPendo]);
        
    const resetSearchBar = () => {
        setSearchText("");
    }

    const onFiltersChange = (hasCitationScore: boolean, dateRange: { start: number; end: number }) => {
        setFilters({ 
            hasCitationScore, 
            dateRange: { 
                start: `${dateRange.start}-01-01`, 
                end: `${dateRange.end}-12-31` 
            } 
        });
    }

    const getAnswer = async () => {
        let response: string = "";
        if (selectedTabFromPath === "science") {
            response = await UseScienceControllerSingleton.sendScienceChatRequest(searchText, filters);
        } else if (selectedTabFromPath === "patents") {
            response = await UsePatentControllerSingleton.sendPatentChatRequest(searchText);  // Patent Filters: add filters as a second argument
        }

        // safety-checks
        if (!response) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, ErrorConstants.COULD_NOT_RETRIEVE_AN_ANSWER);
            setIsLoading(false);
            return;
        }

        setQuestionAnswerPairs(prevQuestionAnswerPairs => [...prevQuestionAnswerPairs, { question: searchText, answer: response }]);
        setIsLoading(false);
    }

    const getDocuments = async (currentSearchText: string, filters: TFilters) => {
        let response: TPatentData[] = [];
        if (selectedTabFromPath === "science") {
            response = await UseScienceControllerSingleton.sendScienceSearchRequest(currentSearchText, filters);
        } else if (selectedTabFromPath === "patents") {
            response = await UsePatentControllerSingleton.sendPatentSearchRequest(currentSearchText); // Patent Filters: add filters as a second argument
        }

        // safety-checks
        if (!response) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, ErrorConstants.COULD_NOT_RETRIEVE_THE_DOCUMENTS);
            setIsLoading(false);
            return;
        }
        setPatentDataResults(response);
        setIsLoading(false);
    }

    const handleSubmit = (event?: FormEvent<HTMLFormElement>) => {
        if (event) event.preventDefault();

        if (!searchText) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, ErrorConstants.PLEASE_ENTER_A_SEARCH_TERM);
            return;
        };
        // set loading
        setIsLoading(true);

        // set search text as URL search param
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('q', searchText);
        navigate(`/${selectedTabFromPath.toLowerCase()}/${selectedSubTabFromPath.toLowerCase()}?${queryParams.toString()}`);

        // log
        LogHelperSingleton.logWithProperties("USESearch", {
            "SearchTab": `${selectedTabFromPath}`,
            "SearchSubTab": `${selectedSubTabFromPath}`,
            "SearchText": `${searchText}`
        });

        // get answer or documents
        if (selectedSubTabFromPath === "question-answering") {
            getAnswer();
        } else if (selectedSubTabFromPath === "documents") {
            getDocuments(searchText, filters);
        }
    }

    const onSelectedTabChange = (currentSelectedTab: string) => {
        const queryParams = new URLSearchParams(location.search);
        navigate(`/${currentSelectedTab.toLowerCase()}/${selectedSubTabFromPath.toLowerCase()}?${queryParams.toString()}`);
        resetRetrievedData();
        resetFilters();
    }

    const onSelectedSubTabChange = (currentSelectedSubTab: string) => {
        const queryParams = new URLSearchParams(location.search);
        navigate(`/${selectedTabFromPath.toLowerCase()}/${currentSelectedSubTab.toLowerCase()}?${queryParams.toString()}`);
        resetRetrievedData();
    }

    const resetRetrievedData = () => {
        setPatentDataResults([]);
        setQuestionAnswerPairs([]);
    }

    const handleGetSimilar = async (selectedItemForGettingSimilars: TPatentData) => {
        const newSearchText = selectedItemForGettingSimilars.title;
        // log
        LogHelperSingleton.logWithProperties("GetSimilar", {
            "SearchTab": `${selectedTabFromPath}`,
            "SearchSubTab": `${selectedSubTabFromPath}`,
            "SearchText": `${newSearchText}`
        });

        // set loading
        setIsLoading(true);

        // set search text as URL search param
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('q', newSearchText);
        navigate(`/${selectedTabFromPath.toLowerCase()}/${selectedSubTabFromPath.toLowerCase()}?${queryParams.toString()}`);

        setSearchText(newSearchText);
        getDocuments(newSearchText, filters);
    }

    const handleSortResults = (column: string, sortType: "asc" | "desc") => {
        const sortedResults = [...patentDataResults].sort((a, b) => {
            if (sortType === "asc") {
                if (a[column as keyof TPatentData] < b[column as keyof TPatentData]) return -1;
                if (a[column as keyof TPatentData] > b[column as keyof TPatentData]) return 1;
                return 0;
            } else {
                if (a[column as keyof TPatentData] > b[column as keyof TPatentData]) return -1;
                if (a[column as keyof TPatentData] < b[column as keyof TPatentData]) return 1;
                return 0;
            }
        });
        setPatentDataResults(sortedResults);
    }

    const resetFilters = () => {
        setReset(!reset);
        onFiltersChange(true, { start: years.startingYear, end: years.currentYear });
    };

    const onLogoClick = () => {
        const queryParams = new URLSearchParams(location.search);
        const bannerText = queryParams.get("bannerText");
        const buttonName = queryParams.get("buttonName");
        let navigatePath = "/";
        if (bannerText) {
            navigatePath = `/?bannerText=${bannerText}`;
        }
        if (buttonName) {
            navigatePath = `${navigatePath}&buttonName=${buttonName}`;
        }
        navigate(navigatePath);
        resetRetrievedData();
        setSearchText("");
        resetFilters();
    }

    return (
        <div className={`${styles.container} ${selectedSubTabFromPath === "documents" ? styles.documents : styles.questionAnswering}`}>
            <div className={styles.mainPageContent}>
                <div className={styles.logoSectionContainer}>
                    <div onClick={onLogoClick} className={styles.logoContainer}>
                        {bannerTextQueryParameter ? (
                            <span className={styles.logoText}>{bannerTextQueryParameter}</span>
                        ) : (
                            <>
                                <img className={styles.logoImage} src={IgorAILogo} alt="Igor^AI Search Logo" />
                                <span className={styles.logoText}>Search</span>
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.doubleTabsContainer}>
                    <div>
                        <h4>Style</h4>
                        <Tabs
                            defaultSelectedTab={!!selectedSubTabFromPath ? selectedSubTabFromPath : "question-answering"}
                            extraClassNames={{ container: styles.tabsContainer }}
                            tabs={[{ name: "Question & Answer", id: "question-answering", icon: faComment }, { name: "Document search", id: "documents", icon: faFile }]}
                            onSelectedTabChange={onSelectedSubTabChange}
                        />
                    </div>
                    <div>
                        <h4>Sources</h4>
                        <Tabs
                            defaultSelectedTab={!!selectedTabFromPath ? selectedTabFromPath : "science"}
                            extraClassNames={{ container: styles.tabsContainer }}
                            tabs={[{ name: "Science", id: "science", icon: faFlask }, { name: "Patents", id: "patents", icon: faAward }]}
                            onSelectedTabChange={onSelectedTabChange}
                        />
                    </div>
                    {selectedTabFromPath === "science" && <div> {/* Patent Filters: Remove this condition */}
                        <Filters
                            years={years}
                            reset={reset}
                            hasProperties={selectedTabFromPath === "science"}
                            onFiltersChange={onFiltersChange}
                        />
                    </div>}
                </div>
                <form onSubmit={handleSubmit} className={styles.searchBarContainer}>
                    <Textbox
                        id="mainSearch"
                        extraClassName={styles.searchBar}
                        placeholder="Ask any question"
                        leftIcon={faSearch}
                        onChange={setSearchText}
                        onEmptyInputButtonClickHandler={resetSearchBar}
                        value={searchText}
                        showEmptyInputCrossIcon={!!searchText}
                        extraClassNames={{ iconContainerLeft: styles.searchBarIconContainer }}
                    />
                    <Button
                        title={searchButtonTitle}
                        buttonType="primary"
                        iconName={faPaperPlaneTop}
                        onClick={handleSubmit}
                        extraClassName={styles.searchButton}
                    />
                </form>
                {selectedSubTabFromPath === "question-answering" && (
                    <Chat
                        selectedTab={selectedTabFromPath}
                        isLoading={isLoading}
                        searchText={searchText}
                        questionAnswerPairs={questionAnswerPairs}
                    />
                )}
                {selectedSubTabFromPath === "documents" && (
                    <Search
                        isLoading={isLoading}
                        patentDataResults={patentDataResults}
                        handleGetSimilarResults={handleGetSimilar}
                        handleSortResults={handleSortResults}
                        selectedTab={selectedTabFromPath}
                        searchText={searchText}
                    />
                )}
            </div>
        </div>
    );
}
