class LocalStorageHelper {
    public getItem<T>(key: string): (T | undefined) {
        try {
            const item: string | null = localStorage.getItem(key);
            return item ? JSON.parse(item) : undefined;
        } catch {
            return undefined;
        }
    }

    public setItem<T>(item: T, key: string): void {
        localStorage.setItem(key, JSON.stringify(item));
    }
}

// export the LocalStorageHelper class as a Singleton
export const LocalStorageHelperSingleton = new LocalStorageHelper();