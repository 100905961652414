import { FC, useMemo } from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
// Components
import { Dropdown } from 'Components';

export const ExportButton: FC = () => {
    const exportOptions = useMemo(() => [
        { name: "CSV", value: "csv" },
        { name: "MS-EXCEL", value: "excel" },
        { name: "TXT", value: "txt" }
    ], []);

    const onClickExportOption = (exportOptionName: string) => {
        const exportOption = exportOptions.find(option => option.name === exportOptionName);
        if (!exportOption) return;
        $('#js-documentsTable').tableExport({ type: exportOption.value, fileName: "IGOR AI Search - Documents" });
    }
    
    return (
        <Dropdown
            onOptionSelect={onClickExportOption}
            options={exportOptions.map(option => option.name)}
            iconName={faDownload}
            buttonTitleAttribute="Export"
        />
    );
}
