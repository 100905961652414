// node_modules
import {
    useEffect,
    RefObject
} from "react";

export const useClickOutsideRef = (ref: RefObject<HTMLElement>, handleClickOutsideRef: () => void): void => {
    useEffect(() => {
        const onMouseDownListener = (mouseEvent: MouseEvent): void => {
            if (ref &&
            ref.current && 
            mouseEvent &&
            mouseEvent.target &&
            mouseEvent.target instanceof HTMLElement && 
            !ref.current.contains(mouseEvent.target)) {
                handleClickOutsideRef();
            }
        };

        // bind the event listener
        document.addEventListener("mousedown", onMouseDownListener);

        return () => {
            // unbind the event listener on clean up
            document.removeEventListener("mousedown", onMouseDownListener);
        };
    }, [ref, handleClickOutsideRef]);
};
