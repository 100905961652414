import { FC } from 'react';
// Styles
import styles from "./chat.module.scss";
// Components
import { LoadingStatusIndicator } from 'Components';
import { TooltipItem } from './TooltipItem';
// Enums
import { LoadingStatusEnum } from 'Enums';

type TChatProps = {
    searchText: string,
    isLoading: boolean,
    questionAnswerPairs: TQuestionAnswerPair[],
    selectedTab: string,
}

type TQuestionAnswerPair = {
    question: string,
    answer: string,
};

export const Chat: FC<TChatProps> = ({ searchText, isLoading, questionAnswerPairs, selectedTab }) => {
    // Logic
    const answerWithTooltip = (answer: string, question: string, questionAnswerPairIndex: number) => answer.split(/(<span.*?<\/span>)/).map((item: string, index: number) => {
        if (!item.startsWith('<span')) {
            return item;
        } else {
            // Example item: <span id="id" title="None">3</span>
            // Extract the title and counter (content inside the span) from the span element
            const titleMatch = item.match(/title="(.*?)"/);
            const contentMatch = item.match(/<span.*?>(.*?)<\/span>/);
            const linkMatch = item.match(/id="(.*?)"/);
            const title = titleMatch ? titleMatch[1] : '';
            const content = contentMatch ? contentMatch[1] : '';
            const link = linkMatch ? linkMatch[1] : '';
            return (
                <TooltipItem
                    key={`${questionAnswerPairIndex}_${index}`}
                    link={link}
                    item={content}
                    tooltipText={title}
                    selectedTab={selectedTab}
                    question={question}
                    searchText={searchText}
                />
            )
        }
    })

    return (
        <div className={styles.chatContainer}>
            {isLoading && (
                <div className={styles.questionAnswerPair}>
                    <div className={`${styles.qaBox} ${styles.question}`}>
                        <p>{searchText}</p>
                    </div>
                    <div className={`${styles.qaBox} ${styles.loadingBox} ${styles.answer}`}>
                        <LoadingStatusIndicator size={32} status={LoadingStatusEnum.Loading} />
                    </div>
                </div>
            )}
            <div>
                {[...questionAnswerPairs].reverse().map((item, index) => (
                    <div className={styles.questionAnswerPair} key={index}>
                        <div className={`${styles.qaBox} ${styles.question}`}>
                            <p>{item.question}</p>
                        </div>
                        <div className={`${styles.qaBox} ${styles.answer}`}>
                            <p>{answerWithTooltip(item.answer, item.question, index)}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
