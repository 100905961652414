// node_modules
import { useMemo, useState } from "react";

export const usePagination = (countInterval: number, totalCount: number, onPaginate?: (isPaginateNext: boolean) => void): {
    paginationIndicator: string,
    paginateToNextPage: () => void,
    paginateToPreviousPage: () => void,
    currentPageNumber: number,
    isLastPage: boolean,
    resetPagination: () => void,
    setTotalCount: (newTotalCount: number) => void,
    setCurrentPageNumber: (pageNumber: number) => void,
}=> {
    // State
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    const [currentTotalCount, setCurrentTotalCount] = useState<number>(totalCount);

    // Const
    const currentStartNumber = useMemo(() => currentPageNumber === 1 ? 1 : ((currentPageNumber - 1) * countInterval) + 1,
        [countInterval, currentPageNumber]);
    const estimatedEndNumber = useMemo(() => currentStartNumber + countInterval - 1,
        [countInterval, currentStartNumber]);
    const isLastPage = useMemo(() => estimatedEndNumber >= currentTotalCount, 
        [currentTotalCount, estimatedEndNumber]);
    const currentEndNumber = useMemo(() => isLastPage ? currentTotalCount : estimatedEndNumber,
        [currentTotalCount, estimatedEndNumber, isLastPage]);
    const paginationIndicator = useMemo(() => `Showing ${currentStartNumber} to ${currentEndNumber} of ${currentTotalCount} rows`, [currentEndNumber, currentStartNumber, currentTotalCount]);
    // Logic
    const resetPagination = (): void => {
        // reset current page number
        // then every const that depends on current page number will be updated
        setCurrentPageNumber(1);
    };

    const paginateToPreviousPage = (): void => {
        if (currentPageNumber > 1) {
            setCurrentPageNumber(currentPageNumber - 1);
            if (onPaginate) {
                onPaginate(false);
            }
        }
    };

    const paginateToNextPage = (): void => {
        if (currentPageNumber * countInterval < currentTotalCount) {
            setCurrentPageNumber(currentPageNumber + 1);
            if (onPaginate) {
                onPaginate(true);
            }
        }
    };

    // Return
    return { paginationIndicator, paginateToNextPage, paginateToPreviousPage, currentPageNumber,
        isLastPage, resetPagination, setTotalCount: setCurrentTotalCount, setCurrentPageNumber };
};