// node_modules
import { FC, useCallback, useEffect, useState } from "react";
// Components
import { Button } from "Components";
// Types
import { TTab } from "Types";
// Styles
import styles from "./tabs.module.scss";

export type TabsProps = {
    onSelectedTabChange?: (selectedTab: string) => void,
    disabledTabs?: TTab[],
    tabs: TTab[],
    extraTabNaming?:  { [key: string]: string },
    extraClassNames?: { container?: string, tab?: string },
    defaultSelectedTab?: string,
};

export const Tabs: FC<TabsProps> = ({ tabs, onSelectedTabChange, disabledTabs, extraTabNaming, extraClassNames, defaultSelectedTab }: TabsProps) => {
    // State
    const [selectedTab, setSelectedTab] = useState<string | undefined>(defaultSelectedTab ?? (tabs.find((tab: TTab) => !isTabDisabled(tab))?.id ?? undefined));

    useEffect(() => {
        setSelectedTab(defaultSelectedTab);
    }, [defaultSelectedTab]);

    // Logic
    const isTabDisabled = useCallback((tab: TTab) => {
        // if disabled tabs is defined and tab is in disabled tabs
        return !!disabledTabs && disabledTabs.length > 0 && !!disabledTabs.find((disabledTab: TTab) => disabledTab.name === tab.name);
    }, [disabledTabs]);

    const isTabSelected = useCallback((tab: TTab) => {
        return selectedTab === tab.id;
    }, [selectedTab]);

    // Logic
    const onSelectedTab = (tab: string) => {
        // set selected tab state
        setSelectedTab(tab);

        // if on selected tab change callback is defined
        if(onSelectedTabChange) {
            // call it
            onSelectedTabChange(tab);
        }
    };

    // Render
    return (
        <div className={`${styles.tabs} ${extraClassNames?.container || undefined}`}>
            {tabs.map(tab => {
                return (
                    <Button
                        key={tab.name}
                        onClick={() => isTabDisabled(tab) || isTabSelected(tab) ? null : onSelectedTab(tab.id ?? tab.name)}
                        title={tab.name}
                        iconName={tab.icon}
                        isDisabled={isTabDisabled(tab)}
                        extraClassName={`${styles.tab} ${selectedTab === tab.id ? styles.selected : ""} ${isTabDisabled(tab) ? styles.disabled : ""} ${extraClassNames?.tab || ""}`}
                    >
                        {extraTabNaming && <h4>{extraTabNaming[tab.name] ? `${tab.name} ${extraTabNaming[tab.name]}` : tab.name }</h4>}
                    </Button>
                );
            })}
        </div>
    );
};