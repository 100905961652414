import { FC, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faTableCells } from '@fortawesome/free-solid-svg-icons';
// Components
import { Popover, Checkbox, Button } from 'Components';
// Hooks
import { useClickOutsideRef } from 'Hooks';

type TColumnSelectionButton = {
    onSelectionChange: (selectedColumns: string[]) => void,
    extraClassNames?: { container?: string },
    columnOptions: string[]
}

export const ColumnSelectionButton: FC<TColumnSelectionButton> = ({ onSelectionChange, extraClassNames = {}, columnOptions }) => {

    // State
    const [columnSelectionButtonRef, setColumnSelectionButtonRef] = useState<HTMLDivElement | null>(null);
    const [isPopoverShown, setIsPopoverShown] = useState<boolean>(false);
    const [selectedColumns, setSelectedColumns] = useState<string[]>(columnOptions);

    // Ref
    const columnSelectionButtonContainerRef = useRef<HTMLDivElement>(null);

    // Logic
    const onCheckboxChange = (isChecked: boolean, columnName: string) => {
        const columnOptionIndex = columnOptions.indexOf(columnName);
        const newSelectedColumns = [...selectedColumns];
        if (isChecked) {
            newSelectedColumns[columnOptionIndex] = columnName;
        } else {
            newSelectedColumns[columnOptionIndex] = "";
        }
        setSelectedColumns(newSelectedColumns);
        onSelectionChange(newSelectedColumns);
    }

    const showPopover = () => {
        setIsPopoverShown(true);
    }

    // Custom hooks
    useClickOutsideRef(columnSelectionButtonContainerRef, () => { setIsPopoverShown(false); });
    
    return (
        <div className={extraClassNames?.container} ref={columnSelectionButtonContainerRef}>
            <div ref={setColumnSelectionButtonRef}>
                <Button titleAttribute="Show/Hide columns" onClick={showPopover} title="" buttonType="secondary" iconName={faTableCells}>
                    <FontAwesomeIcon icon={faCaretDown} />
                </Button>
            </div>
            <Popover referenceEl={columnSelectionButtonRef} isOpen={isPopoverShown} placement="bottom-end">
                <div>
                    {columnOptions.map((columnOption, index) => {
                        return (
                            <Checkbox key={index} text={columnOption} theme="textLabel"
                                isChecked={selectedColumns.includes(columnOption)}
                                onCheckboxChange={(isChecked: boolean) => { onCheckboxChange(isChecked, columnOption); }}
                            />
                        );
                    })}
                </div>
            </Popover>
        </div>
    );
}
