import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
// Styles
import styles from "./paginator.module.scss";

type TPaginatorProps = {
    pageCount: number,
    selectedPage: number,
    onPageChange: (newPageNumber: number) => void
}

const PageItem: FC<{ page: number, selected: boolean, onClick: () => void }> = ({ page, selected, onClick }) => (
    <li onClick={onClick} className={`${styles.pageItem} ${selected ? styles.active : ""}`}>{page}</li>
);

export const Paginator: FC<TPaginatorProps> = ({ pageCount, selectedPage, onPageChange }) => {
    // Logic
    const onPageItemClick = (newPageNumber: number) => {
        if (onPageChange) onPageChange(newPageNumber);
    }

    const isPageCountBiggerThanNine = pageCount > 9;
    const isSelectedOptionSmallerThanFive = selectedPage < 5;
    const isSelectedOptionBiggerThanPageCountMinusFour = selectedPage > pageCount - 4;

    let pages = [];

    if (!isPageCountBiggerThanNine) {
        pages = Array.from(Array(pageCount).keys()).map(i => i + 1);
    } else if (isSelectedOptionSmallerThanFive) {
        pages = [1, 2, 3, 4, 5, '...', pageCount];
    } else if (isSelectedOptionBiggerThanPageCountMinusFour) {
        pages = [1, '...', pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
    } else {
        pages = [1, '...', selectedPage - 1, selectedPage, selectedPage + 1, '...', pageCount];
    }

    return (
        <div>
            <ul className={styles.pagination}>
                <li key="Previous" onClick={() => { if (selectedPage !== 1) { onPageItemClick(selectedPage - 1); } }} className={`${styles.pageItem} ${styles.chevron} ${selectedPage === 1 ? styles.disabled : ""}`}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </li>
                {pages.map((page, index) => 
                    typeof page === 'number' ? 
                        <PageItem key={index} page={page} selected={selectedPage === page} onClick={() => onPageItemClick(page)} /> :
                        <li key={index} className={`${styles.pageItem} ${styles.dots} ${styles.disabled}`}>{page}</li>
                )}
                <li key="Next" onClick={() => { if (selectedPage !== pageCount) { onPageItemClick(selectedPage + 1); } }} className={`${styles.pageItem} ${styles.chevron} ${selectedPage === pageCount ? styles.disabled : ""}`}>
                    <FontAwesomeIcon icon={faAngleRight} />
                </li>
            </ul>
        </div>
    );
};
